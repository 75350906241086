.map-position {
  position: fixed;
}

.custom-popup .mapboxgl-popup-content {
  background-color: #00838f;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 280px;
  padding: 0px;
  border-radius: 6px;
}

.custom-popup .mapboxgl-popup-content-wrapper a {
  color: white;
}

.custom-popup .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #00838f;
}

.custom-popup .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #00838f;
}

.custom-popup .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #00838f;
}

.custom-popup .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #00838f;
}

.custom-popup .merchant-id {
  position: relative;
  top: -2px;
  left: 2px;
  opacity: 0.7;
}

.custom-popup .country-name {
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}

.custom-popup .local-time {
  display: block;
  position: relative;
  text-align: center;
  width: 100%;
  top: -5px;
  opacity: 0.7;
}

.custom-popup .description {
  display: inline-block;
  text-align: right;
  width: 30%;
  top: -13px;
  opacity: 0.7;
  margin-right: 6px;
}

.custom-popup .value {
  font-size: 0.9rem;
  font-weight: 500;
}

.location .merchant-id {
  position: relative;
  top: -2px;
  left: 2px;
  opacity: 0.7;
}

.location .country {
  font-weight: 600;
  font-size: larger;
}

.e-popup.e-multi-select-list-wrapper .e-list-item {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.e-dropdownbase .e-list-item {
  line-height: 28px;
}

.e-ddt.e-input-group.e-control-wrapper .e-clear-icon,
.e-ddt.e-float-input.e-control-wrapper .e-clear-icon {
  background-color: #00838f !important;
}

.e-ddt.e-input-group.e-control-wrapper .e-input-group-icon.e-ddt-icon,
.e-ddt.e-float-input.e-control-wrapper .e-input-group-icon.e-ddt-icon {
  background-color: #00838f !important;
}

.e-list-text {
  padding-left: 10px;
}

.e-ddt.e-popup .e-treeview.e-fullrow-wrap .e-text-content {
  display: flex;
}

.e-ddt.e-popup .e-treeview .e-fullrow {
  height: 10px !important;
}

.status-open-debts-chart .apexcharts-legend {
  justify-content: left !important;
  width: 300px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.status-open-debts-chart .apexcharts-legend-series {
  width: 30% !important;
}

@media screen and (max-width: 768px) {
  .status-open-debts-chart .apexcharts-legend {
    width: 200px !important;
  }
  .status-open-debts-chart .apexcharts-legend-series {
    width: 100% !important;
  }
}

@media screen and (max-width: 1400px) {
  .status-open-debts-chart .apexcharts-legend {
    width: 200px !important;
  }
  .status-open-debts-chart .apexcharts-legend-series {
    width: 45% !important;
  }
}
